<template>
  <v-container fluid>
    <template v-if="!isLoading">
      <v-row>
        <v-col class="text-center text-body-1">
          <span v-if="isSuccess">{{
            $trans("zoom_auth_success_description")
          }}</span>
          <span v-else>{{ $trans("zoom_auth_fail_description") }}</span>
        </v-col>
      </v-row>
      <v-row
        v-if="isSuccess && hasPermission(employeePermissions.EDIT_SERVICES)"
      >
        <v-col>
          <v-btn block outlined color="blue" @click="goToServices">
            {{ $trans("go_to_services_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row v-else>
      <v-col class="text-center">
        <v-progress-circular color="primary" indeterminate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "EnableZoomDialog",
  mixins: [roleActions],
  data() {
    return {
      code: null,
      isLoading: false,
      isSuccess: false,
    };
  },
  created() {
    this.code = this.getDialog.data.code;

    if (this.code) {
      this.isLoading = true;
      this.$emit("loading", this.isLoading);

      api
        .enableZoomForEmployee({
          code: this.code,
        })
        .then(() => {
          this.getUserData().finally(() => {
            this.isSuccess = true;
            this.isLoading = false;
            this.$emit("loading", this.isLoading);
          });
        })
        .catch(() => {
          this.isSuccess = false;
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
        });
    }
  },
  methods: {
    ...mapActions({
      getUserData: "auth/getUserData",
    }),
    goToServices() {
      this.$emit("close");
      this.$router.push({ name: "offer", params: { tab: "services" } });
    },
  },
};
</script>
